import { XtrasService } from './../../../services/xtras.service';
import { IonContent, IonRefresher, IonRefresherContent, RefresherCustomEvent } from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';


@Component({
    selector: 'cy-content',
    templateUrl: './content.component.html',
    styles: [
        `:host { display: contents }`
    ],
    standalone: true,
    imports: [
        IonContent,
        IonRefresher,
        IonRefresherContent,
        CommonModule,
        RouterModule,
    ]
})
export class CYContentComponent {

    @Input() addStyle?: string;
    @Input() addClass?: string;
    @Input() fullscreen: boolean = false;
    @Input() align?: 'center'|'flex-start'|'flex-end'|'stretch'|'space-around'|'space-between'|'space-evenly'|'revert'|'revert-layer';

    @Input() hasRefresh: boolean = false;
    @Output() cyRefresh = new EventEmitter<RefresherCustomEvent>();

    @Input() hasContainer: boolean = true;

    constructor(
        public xServices: XtrasService,
    ) {
    }

    refresh(event: RefresherCustomEvent) {
        this.cyRefresh.emit(event);
    }
}