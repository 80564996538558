import { XtrasService } from './../../../services/xtras.service';
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonBackButton, IonProgressBar, IonTitle, IonIcon, Platform } from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { addIcons } from 'ionicons';
import { chevronBackOutline } from 'ionicons/icons';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
    selector: 'cy-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [
        IonIcon, 
        IonHeader,
        IonToolbar,
        IonMenuButton,
        IonButtons,
        IonBackButton,
        IonProgressBar,
        IonTitle,
        CommonModule,
        RouterModule,
    ],
    styles: [
        `:host { display: contents }`
    ],
})
export class CYHeaderComponent {
  
    @Input() addClass?: string;

    @Input() title?: string;
    @Input() titlePosition: 'start' | 'center' | 'end' = 'start';
    @Input() customTitle: any;

    @Input() hasMenu: boolean = false;
    @Input() menuPosition: 'start' | 'end' = 'end';

    @Input() hasBackButton: boolean = true;
    @Input() defaultUrl: string | null = '/';
    @Input() backButtonTitleIOS: string = 'Atrás';

    @Input() buttonsStart: any;
    @Input() buttonsEnd: any;

    @Input() customHeader: any;
    
    @Input() subHeader: any;

    @Input() hasProgressBar: boolean = false;
    @Input() isLoading: boolean = false;

    constructor(
        public xServices: XtrasService,
        private platform: Platform,
    ) {
        addIcons({ chevronBackOutline });
        this.platform.ready().then(async () => {
            if(this.xServices.isApp()) {
                await StatusBar.setStyle({ style: Style.Light });
            }
        });
    }
}