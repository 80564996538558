<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<ion-content class="content__custom {{ addClass ?? '' }}" [fullscreen]="fullscreen" [style]="addStyle">
    <ion-refresher *ngIf="hasRefresh" slot="fixed" (ionRefresh)="refresh($event)">
        <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    

    @if (hasContainer) {
        <div class="cy__container" [style.justifyContent]="align">
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    } @else {
        <ng-container *ngTemplateOutlet="content"></ng-container>
    }
</ion-content>