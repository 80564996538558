<ng-template #menuBtn>
    <ion-menu-button>
        <i class="fa-regular fa-bars-staggered"></i>
    </ion-menu-button>
</ng-template>

<ion-header [translucent]="true" class="header__custom {{ addClass ?? '' }}">
    <ng-container *ngIf="!customHeader; else hasCustomHeader">
        <ion-toolbar>
            <ion-buttons slot="start">
                <ng-container *ngIf="hasMenu && menuPosition === 'start'">
                    <ng-container *ngTemplateOutlet="menuBtn"></ng-container>
                </ng-container>
    
                <ng-container *ngIf="hasBackButton">
                    <ion-back-button *ngIf="!xServices.isAppIos()" icon="chevron-back-outline" defaultHref="{{ defaultUrl }}" color="primary"></ion-back-button>
                    <ion-back-button *ngIf="xServices.isAppIos()" text="{{ backButtonTitleIOS }}" icon="chevron-back-outline" defaultHref="{{ defaultUrl }}" color="primary"></ion-back-button>
                </ng-container>
    
                <ng-container *ngIf="buttonsStart">
                    <ng-container *ngTemplateOutlet="buttonsStart"></ng-container>
                </ng-container>
            </ion-buttons>
    
            <ng-container *ngIf="(title && title != '') && !customTitle">
                <ion-title class="position__{{ titlePosition }}">{{ title }}</ion-title>
            </ng-container>
            <ng-container *ngIf="customTitle">
                <ng-container *ngTemplateOutlet="customTitle"></ng-container>
            </ng-container>

            <ng-container *ngIf="hasProgressBar && isLoading">
                <ion-progress-bar type="indeterminate"></ion-progress-bar>
            </ng-container>
    
            <ion-buttons slot="end">
                <ng-container *ngIf="buttonsEnd">
                    <ng-container *ngTemplateOutlet="buttonsEnd"></ng-container>
                </ng-container>
                
                <ng-container *ngIf="hasMenu && menuPosition === 'end'">
                    <ng-container *ngTemplateOutlet="menuBtn"></ng-container>
                </ng-container>
            </ion-buttons>
        </ion-toolbar>
        <ion-toolbar *ngIf="subHeader">
            <ng-container *ngTemplateOutlet="subHeader"></ng-container>
        </ion-toolbar>
    </ng-container>
    <ng-template #hasCustomHeader>
        <ng-container *ngTemplateOutlet="customHeader"></ng-container>
    </ng-template>
</ion-header>