import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IonButton } from '@ionic/angular/standalone';

@Component({
  selector: 'ion-button-cy',
  template: `
    <ion-button
      [type]="type"
      [class]="addClass + ' cy__btn'"
      (click)="action($event)"
      [disabled]="disabled"
      [size]="size"
      [color]="color"
      [fill]="fill"
      [shape]="shape"
      [expand]="expand"
      >
      <div class="inner__btn">
        <i *ngIf="icon" [class]="icon"></i><span *ngIf="!iconOnly&&label">{{ label }}</span>
        <ng-content></ng-content>
      </div>
    </ion-button>
  `,
  styles: [
    `:host { display: contents }`
  ],
  standalone: true,
  imports: [
    IonButton,
    CommonModule,
    MatIconModule,
  ]
})
export class IonButtonCapYeiComponent {

  @Input() addClass:string = '';
  @Input() label!: string;
  @Input() icon?:string;
  @Input() iconOnly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: string = 'button';
  @Output() onPress = new EventEmitter<any>();
  
  @Input() size: 'default'|'small'|'large' = 'default';
  @Input() color?: 'primary'|'secondary'|'tertiary'|'success'|'warning'|'danger'|'light'|'medium'|'dark';
  @Input() fill?: 'clear'|'outline'|'solid';
  @Input() shape?: 'round';
  @Input() expand?: 'block'|'full';

  constructor() {}

  action(event: MouseEvent) {
    this.onPress.emit(event);
  }
}
